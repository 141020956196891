// Third-party libraries.
import { Component } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import Select, { OnChangeValue, OptionProps, Props, components } from 'react-select';

// Components.
import styles from 'Components/FormField/FormField.module.css';
import { FormLabel } from 'Components/FormField/FormLabel/FormLabel';
import { FormFieldTooltip } from 'Components/Tooltips/FormFieldTooltip';
// Helpers.
import { getUserNameFromUser, sortFormattedUsers } from 'Helpers/UserUtils';
// Models.
import { ColorTheme } from 'Models/Types/GlobalType';
import { Status, UserResponse } from 'Models/User';

// Local code.
import UserOption, { UserOptionProps } from './UserOption/UserOption';

interface FormFieldUserMultiSelectProps {
    formFieldId: string;
    formFieldLabel: string;
    isRequiredField?: boolean;
    tooltip?: string;
    invalidMessage?: string;
    onUsersSelected?: (value: UserResponse[] | undefined, formFieldId: string) => void;
    selectedUsers?: UserResponse[];
    disabled?: boolean;
    users: UserResponse[];
    includeInactive?: boolean;

    /**
     * The type of background that the component will be displayed against. If not supplied, `'light'` will be used.
     */
    colorTheme?: ColorTheme;
}

class UserResponseOption {
    value: string;
    label: string;
    user: UserResponse;

    constructor(user: UserResponse) {
        this.value = user.cognito_subject;
        this.label = getUserNameFromUser(user);
        this.user = user;
    }
}

// TODO: can this be combined with FormFieldMultiOptionSelect? They share quite a bit of code.
export default class FormFieldUserMultiSelect extends Component<FormFieldUserMultiSelectProps> {
    colorTheme = this.props.colorTheme ?? 'light';

    mapUserToUserOption = (user: UserResponse): UserResponseOption => new UserResponseOption(user);

    filterAndFormatUsers = (): UserResponseOption[] => {
        let users = [...this.props.users];

        if (!this.props.includeInactive) {
            users = users.filter((user) => user.status === Status.ACTIVE);
        }

        return sortFormattedUsers(users).map(this.mapUserToUserOption);
    };

    handleChange = (value: OnChangeValue<UserResponseOption, true>): void => {
        if (this.props.onUsersSelected) {
            if (value && value instanceof Array) {
                this.props.onUsersSelected(
                    value.map((userOption) => userOption.user),
                    this.props.formFieldId
                );
            } else {
                this.props.onUsersSelected(undefined, this.props.formFieldId);
            }
        }
    };

    userOption = (props: OptionProps<UserResponseOption, true>): JSX.Element => {
        const userOptionProps: UserOptionProps = {
            user: props.data.user,
        };
        return (
            <components.Option {...props}>
                <UserOption {...userOptionProps} />
            </components.Option>
        );
    };

    render(): JSX.Element {
        const selectProps: Props<UserResponseOption, true> = {
            options: this.filterAndFormatUsers(),
            onChange: this.handleChange,
            value: this.props.selectedUsers?.map(this.mapUserToUserOption),
            isDisabled: this.props.disabled,
            isMulti: true,
            inputId: this.props.formFieldId,
        };
        return (
            <FormGroup controlId={this.props.formFieldId} className={styles.formField}>
                {this.props.formFieldLabel && (
                    <FormLabel color={this.colorTheme === 'light' ? undefined : 'white'} required={this.props.isRequiredField}>
                        {this.props.formFieldLabel}
                    </FormLabel>
                )}
                {this.props.tooltip && <FormFieldTooltip text={this.props.tooltip} />}
                <Select
                    {...selectProps}
                    components={{ Option: this.userOption }}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 9,
                        colors: {
                            ...theme.colors,
                            primary25: '#05263717',
                            text: 'var(--hps-blue)',
                            primary: this.colorTheme === 'light' ? 'var(--hps-blue)' : 'transparent',
                        },
                    })}
                    styles={{
                        ...styles,
                        control: (base) => ({
                            ...base,
                            borderColor: '#053c591a',
                            color: this.colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-white)',
                            backgroundColor: this.colorTheme === 'light' ? base.backgroundColor : 'var(--hps-blue)',
                        }),
                        multiValue: (styles) => ({
                            ...styles,
                            backgroundColor: this.colorTheme === 'light' ? styles.backgroundColor : 'var(--hps-white)',
                        }),
                        multiValueRemove: (styles, _) => ({
                            ...styles,
                            color: this.colorTheme === 'light' ? 'var(--hps-gray)' : 'var(--hps-blue)',
                            ':hover': {
                                color: this.colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-gray)',
                            },
                        }),
                        option: (styles, { isSelected }) => ({
                            ...styles,
                            backgroundColor: isSelected ? '#05263717' : '',
                            color: isSelected ? 'var(--hps-blue)' : '',
                        }),
                        input: (base) => ({
                            ...base,
                            color: this.colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-white)',
                        }),
                        placeholder: (base) => ({
                            ...base,
                            color: this.colorTheme === 'light' ? base.color : 'var(--hps-white)',
                        }),
                        dropdownIndicator: (base) => ({
                            ...base,
                            color: this.colorTheme === 'light' ? base.color : 'var(--hps-white)',
                            ':hover': {
                                color: this.colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-gray)',
                            },
                        }),
                        clearIndicator: (base) => ({
                            ...base,
                            color: this.colorTheme === 'light' ? base.color : 'var(--hps-white)',
                            ':hover': {
                                color: this.colorTheme === 'light' ? 'var(--hps-blue)' : 'var(--hps-gray)',
                            },
                        }),
                    }}
                />
                <FormControl.Feedback type="invalid">{this.props.invalidMessage || 'Please enter a valid value'}</FormControl.Feedback>
            </FormGroup>
        );
    }
}
